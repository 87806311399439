<template>
  <div class="content">
    <div class="top">
      <div class="img-logo">
        <img
          @click="$router.push({ path: '/' })"
          src="../../src/assets/img/bhlogo.png"
          alt="无法查看"
        />
      </div>
      <div class="text-login">
        <a href="https://bhtcm.cn:3002" target="_blank"
          ><span class="icon iconfont icon-denglu"></span
        ></a>
      </div>
      <div class="text-menu">
        <p v-for="(item, index) in menuList" :key="index">
          <span @click="onRouterPush(item)" :class="inClassActive(item)">{{
            item.meta.title
          }}</span>
        </p>

        <span>
          <a target="_blank" href="https://bhtcm.cn:8891/">焙荒看板</a>
        </span>
      </div>
    </div>
    <div class="banner" v-show="isShowBanner()">
      <div class="swiper">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in banner"
            :key="index"
          >
            <img alt="无法查看" :src="getImgView(item.img)" />
          </div>
        </div>
        <!-- If we need navigation buttons -->
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/swiper-bundle.min.css"; // 所有 Swiper 样式，包括所有模块样式（如导航、分页等）
import Swiper, { Navigation, Pagination, Scrollbar, Autoplay } from "swiper"; // 导入您需要的模块
import { bannerList } from "@/request/api";
import { getFileAccessHttpUrl } from "@/utlis/index";
export default {
  name: "topMenu",
  props: {
    msg: String,
  },

  data: function () {
    return {
      routes: [],
      selectRouter: null,
      banner: [],
      notDisplayedBanner: [
        "/healthyCategory",
        "/article",
        "/about",
        "/healthyTypeArticleList",
      ],
      menuList: [
        {
          path: "/",
          name: "home",
          component: () =>
            import(/* webpackChunkName: "about" */ "../views/index/index.vue"),
          meta: {
            title: "首页",
            show: true,
            keepAlive: false,
            bannerShow: true,
          },
        },
        {
          path: "/healthyCategory",
          name: "healthyCategory",
          component: () =>
            import(
              /* webpackChunkName: "about" */ "../views/healthyArticle/category.vue"
            ),
          meta: {
            title: "健康圈",
            show: true,
            keepAlive: true,
            bannerShow: false,
          },
        },
        {
          path: "/channel",
          name: "channel",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () =>
            import(
              /* webpackChunkName: "about" */ "../views/channel/index.vue"
            ),
          meta: {
            title: "焙荒频道",
            show: true,
            keepAlive: false,
            bannerShow: true,
          },
        },
        {
          path: "/about",
          name: "about",

          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () =>
            import(/* webpackChunkName: "about" */ "../views/about/index.vue"),
          meta: {
            title: "关于焙荒",
            show: true,
            keepAlive: false,
            bannerShow: true,
          },
        },
      ],
    };
  },
  watch: {
    // '$route': {
    //   handler(to){
    //
    //     this.selectRouter = to.name
    //
    //   },
    //   deep: true, // 深度监听
    //   immediate: true, // 第一次初始化渲染就可以监听到
    // },
  },
  mounted() {
    setTimeout(() => {
      this.selectRouter = this.$route.path;
    }, 200);
    this.routes = this.$router.options.routes;

    this.getList();
  },
  methods: {
    isShowBanner() {
      let item = this.notDisplayedBanner.find((item) => {
        return item === this.$route.path;
      });
      return !item;
    },
    getList() {
      let that = this;
      bannerList().then((res) => {
        this.banner = res.result;
        console.log(res);
        this.$nextTick(() => {
          new Swiper(".swiper", {
            loop: true,
            speed: 400,
            spaceBetween: 100,
            autoplay: {
              delay: 5000,
            },
            modules: [Navigation, Pagination, Scrollbar, Autoplay],
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
            scrollbar: {
              el: ".swiper-scrollbar",
              draggable: true,
            },
            on: {
              click: function (item) {
                that.onSwiperClick(that.banner[item.realIndex]);
              },
            },
          });
        });
      });
    },
    onRouterPush(item) {
      this.selectRouter = item.path;
      this.$router.push({ path: item.path });
    },
    onSwiperClick(item) {
      if (item.type === 0) {
        return true;
      } else if (item.type === 1) {
        window.open(item.url, "_blank");
      } else if (item.type === 2) {
        this.$router.push({ path: "/article", query: { id: item.url } });
      }
    },
    inClassActive(item) {
      if (this.selectRouter === item.path) {
        return "span_active";
      } else {
        return "none";
      }
    },
    getImgView(text) {
      if (text && text.indexOf(",") > 0) {
        text = text.substring(0, text.indexOf(","));
      }
      return getFileAccessHttpUrl(text);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.content {
  a {
    text-decoration: none;
  }
  .top {
    width: 1200px;
    margin: auto;
    height: 100px;
    overflow: hidden;
    .img-logo {
      cursor: pointer;
      float: left;
      display: flex;
      align-items: center;
      width: 335px;
      img {
        width: 80px;
        height: 58px;
        margin-top: 20px;
      }
      span {
        margin-left: 10px;
      }
    }

    .text-menu {
      float: right;
      height: 100px;
      line-height: 100px;
      text-align: center;
      p {
        display: inline-block;
        margin: 0;
      }
      span {
        cursor: pointer;
        display: inline-block;
        width: 140px;
        box-sizing: border-box;
        height: 100px;
      }
      span:hover {
        color: #0025bd;
      }
      .span_active {
        color: #0025bd;
        border-bottom: 2px #0025bd solid;
      }

      a {
        color: black;
      }
      a:hover {
        color: #0025bd;
      }
    }
    .text-login {
      width: 50px;
      float: right;
      line-height: 100px;
      text-align: center;
    }
    .icon {
      font-size: 25px;
    }
    .icon:hover {
      color: #0025bd;
      cursor: pointer;
    }
  }
  .banner {
    width: 100%;
    height: 520px;
    .swiper {
      width: 100%;
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.swiper {
  --swiper-navigation-color: #eeeeee; /* 单独设置按钮颜色 */
  --swiper-navigation-size: 60px; /* 设置按钮大小 */
  cursor: pointer;
}
</style>
