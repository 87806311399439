import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/index/index.vue'),
    meta:{
      title:'首页',
      show:true,
      keepAlive:false,
      bannerShow:true
    }
  },

  // {
  //   path: '/healthyArticle',
  //   name: 'healthyArticle',
  //   children: [
  //     {
  //       path: 'list',
  //       name:'healthyArticleList',
  //       component: () => import(/* webpackChunkName: "about" */ '../views/healthyArticle/list/index.vue'),
  //     },
  //     {
  //       path: 'article',
  //       name:'healthyArticleArticle',
  //       component: () => import(/* webpackChunkName: "about" */ '../views/healthyArticle/article/index.vue'),
  //     }
  //   ],
  //   component: () => import(/* webpackChunkName: "about" */ '../views/healthyArticle/index.vue'),
  //   meta:{
  //     title:'健康圈'
  //   },
  // },
  {
    path: '/healthyTypeArticleList',
    name: 'healthyCategory',
    component: () => import(/* webpackChunkName: "about" */ '../views/healthyArticle/typeArticleList.vue'),
    meta:{
      title:'健康圈',
      show:false,
      keepAlive:true,
      bannerShow:true
    },
  },
  {
    path: '/article',
    name: 'article',
    component: () => import(/* webpackChunkName: "about" */ '../views/healthyArticle/article/index.vue'),
    meta:{
      title:'文章',
      show:false,
      keepAlive:false,
      bannerShow:true
    },
  },
  {
    path: '/healthyCategory',
    name: 'healthyCategory',
    component: () => import(/* webpackChunkName: "about" */ '../views/healthyArticle/category.vue'),
    meta:{
      title:'健康圈',
      show:true,
      keepAlive:true,
      bannerShow:false,
    },
  },
  {
    path: '/channel',
    name: 'channel',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/channel/index.vue'),
    meta:{
      title:'焙荒频道',
      show:true,
      keepAlive:false,
      bannerShow:true
    },
  },
  {
    path: '/about',
    name: 'about',

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/about/index.vue'),
    meta:{
      title:'关于焙荒',
      show:true,
      keepAlive:false,
      bannerShow:true
    },
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
