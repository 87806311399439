<template>
  <div id="app">
    <topMenu></topMenu>
    <transition name="fade" mode="in-out">
      <router-view  v-if="!$route.meta.keepAlive"   />
    </transition>
    <keep-alive>
      <router-view  v-if="$route.meta.keepAlive"  />
    </keep-alive>


  </div>
</template>
<script>
import topMenu from '@/components/topMenu'

export default {
  components: {
    topMenu
  },
  mounted() {
    document.title = '焙荒诊所'
  }

}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}



.fade-enter {
  opacity:0;
}
.fade-leave{
  opacity:1;
}
.fade-enter-active{
  transition:opacity 0.08s;
}
.fade-leave-active{
  opacity:0;
  transition:opacity 0.08s;
}
</style>
