import request from "./request";

export function bannerList(query) {
  return request({
    url: "/bh/officialWebsite/bannerList",
    method: "get",
    params: query,
  });
}

export function queryChannelList(query) {
  return request({
    url: "/bh/officialWebsite/moreRecommendationsChannel",
    method: "get",
    params: query,
  });
}
export function moreRecommendationsArticle(query) {
  return request({
    url: "/bh/officialWebsite/moreRecommendationsArticle",
    method: "get",
    params: query,
  });
}
export function allArticleCategories(query) {
  return request({
    url: "/bh/officialWebsite/allArticleCategories",
    method: "get",
    params: query,
  });
}

export function allArticlesUnderCategory(query) {
  return request({
    url: "/bh/officialWebsite/allArticlesUnderCategory",
    method: "get",
    params: query,
  });
}
export function queryArticleById(query) {
  return request({
    url: "/bh/officialWebsite/queryArticleById",
    method: "get",
    params: query,
  });
}
export function TreeChildrenArticleCategory(query) {
  return request({
    url: "/bh/officialWebsite/TreeChildrenArticleCategory",
    method: "get",
    params: query,
  });
}
