export const getFileAccessHttpUrl = (fileUrl, prefix = 'http') => {
    let result = fileUrl;
    try {
        if (fileUrl && fileUrl.length > 0 && !fileUrl.startsWith(prefix)) {
            //判断是否是数组格式
            let isArray = fileUrl.indexOf('[') != -1;
            if (!isArray) {
                let prefix = `${process.env.VUE_APP_BASE_DOMAIN}/sys/common/static/`;
                // 判断是否已包含前缀
                if (!fileUrl.startsWith(prefix)) {
                    result = `${prefix}${fileUrl}`;
                }
            }
        }
    } catch (err) {
        console.log(err)
    }
    return result;
};
