import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 引用css初始化
import 'normalize.css/normalize.css'
import "swiper/swiper.min.css"
import "@/assets/iconfont/iconfont.css"

import { Pagination,Loading } from 'element-ui'; // 按需引入组件
import { Button } from 'element-ui'; // 按需引入组件

Vue.component(Pagination.name, Pagination); // 注册全局组件
Vue.component(Button.name, Button); // 注册全局组件
Vue.use(Loading.directive);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
